import logo from './assets/PRISMA-Branding-256.png';
import './App.css';
import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope, FaHeart } from 'react-icons/fa';

let socialLinks = [
  {
    id: "ig",
    fullName: "Instagram",
    url: "instagram.com",
    link: "prsmstyle"
  },
  {
    id: "fb",
    fullName: "Facebook",
    url: "facebook.com",
    link: "PRSMAgency"
  },
  {
    id: "tw",
    fullName: "Twitter",
    url: "twitter.com",
    link: "PrismaAgency"
  },
  {
    id: "mail",
    fullName: "Email"
  }
];

let socialClick = (socialNet) => {
  console.log(`Social link click detected for ${socialNet}`);
  let found = socialLinks.filter(s => s.id === socialNet)[0];
  let compUrl = `https://${found.url}/${found.link}`;
  window.open(compUrl, "_blank");
};

let mailToBehave = () => {
  window.location.assign('mailto:prsmstyle@outlook.com?subject=PRISMA Agency Information Request');
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to PRISMA Agency.</h1>
        <p>
          PRISMA Agency is a firm established in 2020 which helps artists and musicians to grow their fanbase by providing them with the right analytics-driven marketing and communication strategies.<br/>
          We are able to provide tailor-made solutions and consultancies aimed at revealing your full potential to the public with our consolidated experience in the music and style industry.<br/>
          For more information, feel free to contact us through our social media or via e-mail.
        </p>
        <div className="icon-carousel">
          <span>
            <FaInstagram size="2em" onClick={() => socialClick("ig")}/>
            <FaFacebook size="2em" onClick={() => socialClick("fb")} />
            <FaTwitter size="2em" onClick={() => socialClick("tw")} />
            <FaEnvelope size="2em" onClick={() => mailToBehave()}/>
          </span>
        </div>
        <p>
          Made with <FaHeart color="red"/> in Italy - &copy; {new Date().getFullYear()} PRISMA Agency
        </p>
      </header>
    </div>
  );
}

export default App;